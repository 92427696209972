import { css } from '@emotion/react';
import { spin } from './animations';
import { rem } from './functions';
import { mobile, desktopDown } from './mixins';
import { h2, miniHeader } from './typography';

const Override = css`
  ul {
    list-style: none;
  }
  .content {
    ${desktopDown(css`
      &.services-page,
      &.cases-page,
      &.about-page,
      &.home-page,
      &.careers,
      &.insights-page,
      &.contact-us-page,
      &.single-article-page,
      &.content-case {
        position: relative;

        .page-title {
          position: relative;
          z-index: 1;

          h1 {
            position: relative;
          }
        }
      }
    `)}
  }

  .content .page-title {
    padding-top: 70px;
    padding-bottom: 56px;

    ${desktopDown(css`
      padding-top: 66px;
    `)}

    &.logo-title {
      padding-bottom: 30px;

      img {
        -webkit-animation: ${spin} 15s linear infinite;
        -moz-animation: ${spin} 15s linear infinite;
        animation: ${spin} 15s linear infinite;
      }
    }

    &.h1-title {
      h1 {
        ${h2}
        margin-bottom: 0;
      }
    }

    &.mini-title {
      padding-top: 70px;
      padding-bottom: 64px;
      position: relative;

      h1 {
        ${miniHeader}
        margin-bottom: 0;
        position: relative;
      }
    }

    &.link-title {
      padding-top: 80px;
      padding-bottom: 24px;
      position: relative;

      ${desktopDown(css`
        padding-top: 66px;
        position: relative;
      `)}

      a {
        position: relative;
        z-index: 2;
      }
    }

    ${desktopDown(css`
      padding-bottom: 24px;
    `)}

    &.logo-title {
      ${desktopDown(css`
        padding-top: 56px;
      `)}
      .logo-container {
        ${desktopDown(css`
          display: flex;
          justify-content: flex-end;
        `)}

        img {
          ${desktopDown(css`
            height: 88px;
            width: 88px;
          `)}
        }
      }
    }

    &.h1-title {
      h1 {
        ${desktopDown(css`
          font-size: ${rem(56)};
          line-height: 68px;
        `)}
      }
    }

    &.mini-title {
      ${desktopDown(css`
        padding-top: 64px;
        padding-bottom: 8px;
      `)}
    }
  }
  .image-container {
    .gatsby-image-wrapper {
      height: inherit;
      width: auto;
    }
  }

  ${mobile(css`
    .body-1 {
      font-size: ${rem(16)};
      line-height: 26px;
      letter-spacing: 0.2px;
      font-weight: normal;
    }
    p {
      font-size: ${rem(16)};
      line-height: 26px;
    }
  `)}
`;

export default Override;
